<template>
   
   <div class="vh-100 d-flex justify-content-center align-items-center">
        <div class="col-md-6">
            <div class="card  bg-white shadow p-5">
                <div class="mb-1 text-center">
                    <img src="../assets/img/AtsLogo.jpeg" alt="">
                </div>
                <div class="text-center">
                    <h1>"Coming Soon"</h1>
                    <p>"Developers are working on the website." <br>
                    Stay Tuned!</p>
                    <!-- <button class="btn btn-outline">
                        <a href="/index.html">Home</a>
                    </button> -->
                </div>
            </div>
        </div>
    </div>
  
</template>

<script>
export default {
 name: "HomeView",
 data() {
   return {
 

   }


 },
 created: function () {
  
 },

 beforeUnmount: function () {

 },
 methods: {
   depositnow(){
      
   if(this.depositin=== 0){
       alert("please an amount to deposit");
   } else{
       this.balance=this.balance+this.depositin;
       this.deposit=this.deposit+this.depositin
       this.depositin= '';
       alert("Amount is desposited");
   }
   }
 }
}
</script>

